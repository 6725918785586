﻿
table td.shrink,
table th.shrink
{
    width: 1px;
    white-space: nowrap;
}

.card-body + .card-table thead th
{
    border-top-width: 1px;
}
