﻿
.StripeElement--focus
{
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
}

.StripeElement--webkit-autofill
{
    background-color: $input-bg !important;
}

.custom-control-label[for]
{
	&::after
	{
    	cursor: pointer;
	}
}
.custom-control-input[disabled] ~ .custom-control-label[for]::after
{
	cursor: default;

}
.custom-control-input
{
	

    &.previous-value:not(:checked) ~ .custom-control-label::after
    {
        background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='000' fill-opacity='0.6'/></svg>"));
    }

    &:checked ~ .custom-control-label.color-0::before
    {
        background-color: $red;
    }
    &:checked[disabled] ~ .custom-control-label.color-0::before
    {
        background-color: rgba($red, 0.25);
    }

    &:focus ~ .custom-control-label.color-0::before
    {
        box-shadow: 0 0 0 $input-focus-width rgba($red, .25);
    }


    &:checked ~ .custom-control-label.color-1::before
    {
        background-color: $orange;
    }
	&:checked[disabled] ~ .custom-control-label.color-1::before
    {
        background-color: rgba($orange, .5);
    }

    &:focus ~ .custom-control-label.color-1::before
    {
        box-shadow: 0 0 0 $input-focus-width rgba($orange, .25);
    }


    &:checked ~ .custom-control-label.color-2::before
    {
        background-color: $yellow;
    }
    &:checked[disabled] ~ .custom-control-label.color-2::before
    {
        background-color: rgba($yellow, 0.5);
    }

    &:focus ~ .custom-control-label.color-2::before
    {
        box-shadow: 0 0 0 $input-focus-width rgba($yellow, .25);
    }


    &:checked ~ .custom-control-label.color-3::before
    {
        background-color: $blue;
    }
    &:checked[disabled] ~ .custom-control-label.color-3::before
    {
        background-color: rgba($blue, 0.5);
    }

    &:focus ~ .custom-control-label.color-3::before
    {
        box-shadow: 0 0 0 $input-focus-width rgba($blue, .25);
    }

    &:checked ~ .custom-control-label.color-4::before
    {
        background-color: $green;
    }

    &:checked[disabled] ~ .custom-control-label.color-4::before
    {
        background-color: rgba($green, 0.5);
    }

    &:focus ~ .custom-control-label.color-4::before
    {
        box-shadow: 0 0 0 $input-focus-width rgba($green, .25);
    }
}

table form
{
    display: inline;
}

legend
{
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}

.form-group
{
    position: relative;

    .feedback
    {
        //position: absolute;
        text-align: right;
        //right: 0;
        //bottom: -1.5rem;
    }
}

td .form-group
{
    margin-bottom: 0;
}

textarea.form-control
{
    height: 12em;
}


.custom-select
{
    -webkit-appearance: none;
    appearance: none;
}

// this is for input-groups, as the feedback message doesnt get displayed properly. Theres probably a better solution to this
.field-validation-error .invalid-feedback
{
	display: block;
}